<template>
  <div v-show="financialData.list.length" class="elv-report-container">
    <div class="left-div">
      <div class="left-div1">
        <table style="width: 100%">
          <tr>
            <th class="elv-table-double-solid-line" :class="{ 'th-from-to-height': dateTimeValues[0]?.fromDate }">
              <div>
                <div class="elv-table-left-title">
                  {{ unit === 'individuals' ? 'USD' : $t('common.reportUnit', { unit: $t(`common.${unit}`) }) }}
                </div>
              </div>
            </th>
          </tr>
        </table>
      </div>
      <div class="left-div2">
        <table class="left-table2">
          <tr v-for="item in financialStatementShowData" :key="item.fieldId" @click="onClickValue($event, item)">
            <td
              v-if="item?.config?.level === 1 || (!item?.config?.level && !item.parentId)"
              :class="{
                'elv-table-single-solid-line': item?.lineType === 2 || item?.config?.showBottomBorder,
                'custom-made-border': item.config?.lineBold && !item?.config?.level
              }"
            >
              <div
                class="elv-table-level-1-content"
                :class="{
                  'custom-made-content': item.config?.lineBold && item?.config?.level === 1
                }"
              >
                <div>{{ utils.formatLanguageContent(item.name) }}</div>
              </div>
            </td>
            <td
              v-else-if="!item?.config?.level && item.parentId"
              :class="[item?.lineType === 2 ? 'elv-table-single-solid-line' : '']"
            >
              <div class="elv-table-level-2-content">
                <div style="width: 22px; display: flex; align-items: center" />
                <div>{{ utils.formatLanguageContent(item.name) }}</div>
              </div>
            </td>
            <td
              v-else-if="item?.config?.level === 2"
              :class="[item?.lineType === 1 ? 'elv-table-single-line' : 'elv-table-single-solid-line ']"
            >
              <el-tooltip
                ref="elvTableTipsRef"
                :show-arrow="false"
                effect="light"
                placement="top"
                popper-class="elv-table-tips"
                overlay-classname="elv-table-tips"
                :show-after="500"
                :disabled="item?.description && isClickValue ? false : true"
              >
                <div class="elv-table-level-2-content" :class="{ content_bold: item.config?.bold }">
                  <div style="width: 22px; display: flex; align-items: center">
                    <img
                      v-if="isShowArrow && item.whetherExpanded"
                      src="@/assets/img/project/arrow-second-level.png"
                      :style="{ transform: item.expanded ? 'rotate(0deg)' : 'rotate(90deg)' }"
                      @click="onCheckGroupExpanded(item)"
                    />
                  </div>
                  <span>{{ utils.formatLanguageContent(item.name) }}</span>
                </div>
                <template #content>
                  <div class="elv-table-tips-content">{{ utils.formatLanguageContent(item?.description) }}</div>
                </template>
              </el-tooltip>
            </td>
            <!-- v-show="item.expanded" -->
            <td
              v-else-if="item?.config?.level === 3"
              :class="[item?.lineType === 1 ? 'elv-table-single-line' : 'elv-table-single-solid-line ']"
            >
              <div class="elv-table-level-3-content">
                <img v-if="item.logo" :src="item.logo" :onerror="useDefaultImage" :alt="item.name" />
                <img v-else :src="projectDefaultImg" :alt="item.name" />
                <div class="elv-table-level-3-content">{{ item.name }}</div>
                <div class="elv-table-level-3-content-symbol">{{ item.symbol }}</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-scrollbar wrap-class="right-div">
      <div class="right-div1">
        <table class="right-table1">
          <tr>
            <th
              v-for="item in dateTimeValues"
              :key="item.id"
              class="elv-table-double-solid-line elv-table-title"
              style="width: 90px"
            >
              <div :class="{ 'th-from-to-height': item.fromDate }">
                <div v-if="item.title" style="padding-bottom: 3px; line-height: 18px">
                  {{ formatDateTitle(item.title) }}
                </div>
                <div v-if="item.fromDate" class="elv-table-right-title-date">
                  {{ formatDate('fromDate', item.fromDate) }}
                </div>
                <div v-if="item.toDate" class="elv-table-right-title-date" style="padding-bottom: 10px">
                  {{ formatDate('toDate', item.toDate) }}
                </div>
              </div>
            </th>
          </tr>
        </table>
      </div>
      <el-scrollbar wrap-class="right-div2" class="elv-table-right-content">
        <table class="right-table2">
          <tr v-for="item in financialStatementShowData" :key="item.fieldId" @click="onClickValue($event, item)">
            <template v-if="item?.config?.level === 1 && item.type !== 'AGGREGATION'">
              <td
                v-for="(dateItem, dateIndex) in dateTimeValues"
                :key="dateIndex"
                :class="{
                  'elv-table-single-solid-highlight-line': item.config?.showBottomBorder && dateIndex === 0,
                  'elv-table-single-solid-line': item.config?.showBottomBorder,
                  'custom-made-content': item.config?.lineBold
                }"
              >
                <div style="width: 90px"></div>
              </td>
            </template>

            <td
              v-for="(valueItem, i) in item.fieldValues"
              v-show="item?.config?.level !== 3 || item?.config?.level === 3"
              :key="i"
              :class="[
                i === 0
                  ? item?.lineType === 1
                    ? 'elv-table-single-highlight-line'
                    : 'elv-table-single-solid-highlight-line'
                  : item?.lineType === 1
                  ? 'elv-table-single-line'
                  : 'elv-table-single-solid-line',
                item?.config?.level || item?.parentId
                  ? 'elv-table-value-level-2-3-text'
                  : 'elv-table-value-level-agg-text',
                props.isClickValue ? '' : 'cursor_none',
                (item.type === 'ACCOUNT' && route.name !== 'reports-cash-flow-statement' && item.popover) ||
                (item.type === 'AGGREGATION' && item.popover) ||
                (item.config?.level === 3 && item.popover)
                  ? ''
                  : 'cursor_none',
                item.config?.bold ? 'content_bold' : '',
                !item.config?.level && item.config?.lineBold ? 'custom-made-border' : ''
              ]"
            >
              <div
                class="elv-table-value-text"
                :class="{
                  cursor_none:
                    !props.isClickValue ||
                    !(
                      (item.type === 'ACCOUNT' && route.name !== 'reports-cash-flow-statement' && item.popover) ||
                      (item.type === 'AGGREGATION' && item.popover) ||
                      (item.config?.level === 3 && item.popover)
                    ),
                  'is-error': isErrorCell(valueItem)
                }"
                :title="`$${valueItem.value}`"
                :data-index="i"
              >
                <img v-if="isErrorCell(valueItem)" src="@/assets/img/reports/table-warning-filled.png" alt="error" />{{
                  valueItem.value === '' ? '' : utils.numDrCrHandle(unit, valueItem.value)
                }}
              </div>
            </td>
          </tr>
        </table>
      </el-scrollbar>
    </el-scrollbar>
  </div>
  <ComputePopup
    v-if="computePopupVisible"
    :dialog-visible="computePopupVisible"
    dialog-type="ACOUNT"
    balance-type="Dr"
    :unit="unit"
    :date-time="financialValueData.dateTime"
    :event-item="financialValueData.item"
    @closeComputePopup="closeComputePopup"
  />

  <EmptyResult v-if="financialData.list?.length === 0" :img-src="noDataImg" width="125px" height="140px">
    <template #content
      ><p style="font-size: 26px; color: #dde1e6">{{ t('common.noData') }}</p></template
    >
  </EmptyResult>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import ComputePopup from './ComputePopup.vue'
import EmptyResult from '@/components/EmptyResult.vue'
import noDataImg from '@/assets/img/reports/noData.png'
import { useGlobalStore } from '@/stores/modules/global'
import { FinancialStatementDataType } from '#/ReportsTypes'
import { useReportStore } from '@/stores/modules/reports/index'
import projectDefaultImg from '@/assets/img/project/project-empty.png'
import { DateTimeType, FinancialDataItemType } from '#/FinancialsTypes'
import { find, filter, isEmpty, remove, clone, throttle, indexOf } from 'lodash-es'

const route = useRoute()
const { t, locale } = useI18n()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const { entityDetail } = storeToRefs(reportStore)
const { dateTimeList, financialFieldConfig, financialData } = storeToRefs(reportStore)

const props = defineProps({
  // 数据区间
  dateTime: {
    type: Array<number>,
    default: () => {
      return []
    }
  },
  // 表格距离左侧距离
  paddingLeft: {
    type: String,
    default: () => {
      return '20px'
    }
  },
  // 是否显示箭头
  isShowArrow: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  // value值是否可点击
  isClickValue: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  isDialog: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  // 报表类型 (balance-sheet/income-statement/cash-flow-statement)
  financialType: {
    type: String,
    required: true
  },
  isShowRegeneratedInfo: {
    type: Boolean,
    default: false
  }
})
const elvTableTipsRef = ref()
const leftDiv2TouchX = ref()
const leftDiv2TouchY = ref()
const leftDiv2ToTop = ref()

const rightDiv2TouchY = ref()
const rightDiv2ToTop = ref()

const rightDivTouchX = ref()
const isTriggerTouchMove = ref(true)
const computePopupVisible = ref(false)
const financialStatementData: any = ref([])
const financialValueData: any = reactive({
  item: {},
  dateTime: {}
})

const rotate = ref('rotate(0deg)')
const financialStatementTempData = ref()
const dateTimeValues = computed((): DateTimeType[] => {
  let dataList: any = []
  if (props.dateTime.length) {
    dataList = filter(dateTimeList.value, (item: FinancialDataItemType) => {
      return indexOf(props.dateTime, item.id) !== -1
    })
    return dataList
  }
  return dateTimeList.value
})
const containerHeight = computed(() => {
  if (props.dateTime.length > 0) return '100%'
  if (globalStore.showUpGradeInfo) {
    // eslint-disable-next-line no-nested-ternary
    return utils.isMobile()
      ? 'calc(100vh - 265px)'
      : props.isShowRegeneratedInfo
      ? 'calc(100vh - 301px)'
      : 'calc(100vh - 245px)'
  }
  // eslint-disable-next-line no-nested-ternary
  return utils.isMobile()
    ? 'calc(100vh - 234px)'
    : props.isShowRegeneratedInfo
    ? 'calc(100vh - 270px)'
    : 'calc(100vh - 214px)'
})

const titleJustifyContent = computed(() => {
  return navigator.userAgent.indexOf('Firefox') >= 0 ? 'inherit' : 'flex-end'
})
const tableContentHeight = computed(() => {
  if (props.dateTime.length > 0) return '100%'
  if (globalStore.showUpGradeInfo) {
    // eslint-disable-next-line no-nested-ternary
    return utils.isMobile()
      ? 'calc(100vh - 351px)'
      : props.isShowRegeneratedInfo
      ? 'calc(100vh - 374px)'
      : 'calc(100vh - 328px)'
  }
  // eslint-disable-next-line no-nested-ternary
  return utils.isMobile()
    ? 'calc(100vh - 320px)'
    : props.isShowRegeneratedInfo
    ? 'calc(100vh - 343px)'
    : 'calc(100vh - 297px)'
})

const isErrorCell = computed(() => {
  return (item: any) => {
    if (item?.config?.level !== 1) {
      const errorData = find(find(reportStore.financialData.errorList, { date: item?.date })?.fieldValues || [], {
        fieldId: item?.fieldId
      })
      return !isEmpty(errorData)
    }
    return false
  }
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const unit = computed(() => {
  return entityDetail.value?.unit ? entityDetail.value?.unit.toLocaleLowerCase() : 'millions'
})

const financialStatementShowData = computed(() => {
  const tempFieldList: any = []
  if (financialStatementTempData?.value) {
    financialStatementTempData?.value.forEach((item: FinancialStatementDataType) => {
      if (item?.config?.level === 2 && item.expanded) {
        tempFieldList.push(item.fieldId)
      }
    })
    remove(financialStatementTempData?.value, (item: FinancialStatementDataType) => {
      return tempFieldList.indexOf(item?.parentId) !== -1
    })
    for (let i = 0; i < financialStatementTempData.value.length; i += 1) {
      if (financialStatementTempData.value[i].config?.level !== 1) {
        if (
          financialStatementTempData.value[i].type === 'AGGREGATION' &&
          !financialStatementTempData.value[i].config?.hideBottomLine
        ) {
          financialStatementTempData.value[i].lineType = 2
        } else if (
          financialStatementTempData.value[i + 1]?.type &&
          financialStatementTempData.value[i + 1].type === 'AGGREGATION' &&
          !financialStatementTempData.value[i + 1].config?.hideTopLine
        ) {
          financialStatementTempData.value[i].lineType = 2
        } else {
          financialStatementTempData.value[i].lineType = 1
        }
      }
    }
  }
  return financialStatementTempData.value
})
const onCheckGroupExpanded = (item: FinancialStatementDataType) => {
  // 重新拿全量数据
  financialStatementTempData.value = clone(financialStatementData.value)
  rotate.value = item.expanded ? 'rotate(0deg)' : 'rotate(90deg)'
  item.expanded = !item.expanded
}
const useDefaultImage = (event: any) => {
  event.target.src = projectDefaultImg
}
const onClickValue = (e: any, item: FinancialStatementDataType) => {
  const target = e.target || e.srcElement
  if (
    (item.type === 'ACCOUNT' && route.name !== 'reports-cash-flow-statement' && item.popover) ||
    (item.type === 'AGGREGATION' && item.config?.formula?.length) ||
    (item.config?.level === 3 && item.popover)
  ) {
    if (props.isClickValue && target.className.indexOf('elv-table-value-text') !== -1 && target.tagName === 'DIV') {
      const index = target?.getAttribute('data-index')
      financialValueData.item = item
      financialValueData.dateTime = item.fieldValues[index]
      if (item.config?.level === 3) {
        financialValueData.item.type = 'ACCOUNT'
      }
      computePopupVisible.value = true
    }
  }
}
const formatDate = (type: string, date: string) => {
  if (locale.value === 'zh' && type === 'toDate') return `- ${date}`
  if (locale.value === 'zh') return date
  if (type === 'fromDate') return dayjs(date).format('D MMM YYYY')
  if (type === 'toDate') return dayjs(date).format('-D MMM YYYY')
  return date
}

const formatDateTitle = (date: string) => {
  if (props.financialType !== 'balance-sheet' && props.financialType !== 'restrictions')
    return utils.formatLanguageContent(date)
  if (locale.value === 'zh') return date
  return dayjs(date).format('D MMMM\n YYYY')
}
const closeComputePopup = () => {
  computePopupVisible.value = false
  financialValueData.item = {}
  financialValueData.dateTime = {}
}

const rightScroll = () => {
  const top = document.getElementsByClassName('right-div2')[0].scrollTop
  const left = document.getElementsByClassName('left-div1')[0].scrollLeft
  document.getElementsByClassName('left-div2')[0].scrollTop = top
  document.getElementsByClassName('right-div1')[0].scrollLeft = left
}

const rightContentScroll = () => {
  const top = document.getElementsByClassName('right-div2')[0].scrollTop
  const left = document.getElementsByClassName('left-div1')[0].scrollLeft
  document.getElementsByClassName('left-div2')[0].scrollTop = top
  document.getElementsByClassName('right-div1')[0].scrollLeft = left
}

const leftScroll = () => {
  const top = document.getElementsByClassName('right-div1')[0].scrollTop
  const leftDivTop = document.getElementsByClassName('left-div2')[0].scrollTop
  document.getElementsByClassName('left-div1')[0].scrollTop = top
  document.getElementsByClassName('right-div2')[0].scrollTop = leftDivTop
}
const setFinancialStatementData = async (type: string = 'all') => {
  financialStatementData.value = await reportStore.getFinancialStatementData(type === 'all' ? [] : props.dateTime)
  financialStatementTempData.value = clone(financialStatementData.value)
  // 首页默认全部二级折叠
  financialStatementTempData.value.forEach((item: FinancialStatementDataType) => {
    if (item?.config?.level === 2) {
      item.expanded = true
    }
  })
}

const bindTouchEvents = () => {
  const leftDiv2Element = document.getElementsByClassName('left-div2')[0]
  const rightDiv2Element = document.getElementsByClassName('right-div2')[0]
  const rightDivElement = document.getElementsByClassName('right-div')[0]

  if (leftDiv2Element) {
    leftDiv2Element.addEventListener(
      'touchstart',
      (event: any) => {
        // event.preventDefault()
        // 表格拖动
        leftDiv2TouchX.value = event.changedTouches[0].clientX
        leftDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
    leftDiv2Element.addEventListener(
      'touchmove',
      throttle((event: any) => {
        if (isTriggerTouchMove.value) {
          if (event.cancelable) event.preventDefault()

          if (elvTableTipsRef.value.length > 0) {
            elvTableTipsRef.value.forEach((item: any) => {
              item.onClose()
            })
          }
          // 表格拖动
          const offsetX = event.changedTouches[0].clientX - leftDiv2TouchX.value
          const offsetY = event.changedTouches[0].clientY - leftDiv2TouchY.value

          leftDiv2Element.scrollLeft -= offsetX
          leftDiv2Element.scrollTop -= offsetY
          rightDiv2Element.scrollTop -= offsetY

          leftDiv2TouchX.value = event.changedTouches[0].clientX
          leftDiv2TouchY.value = event.changedTouches[0].clientY
          const toTop = leftDiv2Element.scrollTop < 5
          if (leftDiv2ToTop.value !== toTop) {
            leftDiv2ToTop.value = toTop
          }
        }
      }, 30),
      true
    )
    leftDiv2Element.addEventListener(
      'touchend',
      (event: any) => {
        // event.preventDefault()
        leftDiv2TouchX.value = event.changedTouches[0].clientX
        leftDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
  }

  if (rightDivElement) {
    rightDivElement.addEventListener(
      'touchstart',
      (event: any) => {
        // event.preventDefault()
        // 表格拖动
        rightDivTouchX.value = event.changedTouches[0].clientX
        isTriggerTouchMove.value = false
      },
      true
    )
    rightDivElement.addEventListener(
      'touchmove',
      throttle((event: any) => {
        if (event.cancelable) event.preventDefault()
        // 表格拖动
        const offsetX = event.changedTouches[0].clientX - rightDivTouchX.value

        rightDivElement.scrollLeft -= offsetX
        rightDivTouchX.value = event.changedTouches[0].clientX
      }, 30),
      true
    )
    rightDivElement.addEventListener(
      'touchend',
      (event: any) => {
        // event.preventDefault()
        rightDivTouchX.value = event.changedTouches[0].clientX
        isTriggerTouchMove.value = true
      },
      true
    )
  }

  if (rightDiv2Element) {
    rightDiv2Element.addEventListener(
      'touchstart',
      (event: any) => {
        // event.preventDefault()
        // 表格拖动
        rightDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
    rightDiv2Element.addEventListener(
      'touchmove',
      throttle((event: any) => {
        if (event.cancelable) event.preventDefault()
        if (elvTableTipsRef.value.length > 0) {
          elvTableTipsRef.value.forEach((item: any) => {
            item.onClose()
          })
        }
        // 表格拖动
        const offsetY = event.changedTouches[0].clientY - rightDiv2TouchY.value

        rightDiv2Element.scrollTop -= offsetY
        leftDiv2Element.scrollTop -= offsetY

        rightDiv2TouchY.value = event.changedTouches[0].clientY
        const toTop = rightDiv2Element.scrollTop < 5
        if (rightDiv2ToTop.value !== toTop) {
          rightDiv2ToTop.value = toTop
        }
      }, 30),
      true
    )
    rightDiv2Element.addEventListener(
      'touchend',
      (event: any) => {
        // event.preventDefault()
        rightDiv2TouchY.value = event.changedTouches[0].clientY
      },
      true
    )
  }
}

const financialsDataInit = async () => {
  try {
    await reportStore.financialsDataInit(entityId.value, props.financialType)
  } catch (error) {
    console.log(error)
  }
}
emitter.on('financialsDataInit', financialsDataInit)

watch(
  [() => route.name, () => props.financialType, () => entityDetail.value?.entityId],
  async () => {
    if (route.name === `reports-${props.financialType}` && !props.isDialog && entityDetail.value?.entityId !== 0) {
      await financialsDataInit()
    }
  },
  { immediate: true }
)
watch(
  [() => [...props.dateTime], () => financialFieldConfig.value, () => financialData.value.list],
  async () => {
    if (financialFieldConfig.value.length > 0 && financialData.value.list.length > 0) {
      setFinancialStatementData('props')
    }
  },
  { immediate: true }
)
onMounted(async () => {
  nextTick(() => {
    document.getElementsByClassName('right-div')[0]?.addEventListener('scroll', throttle(rightScroll))
    document.getElementsByClassName('right-div2')[0]?.addEventListener('scroll', throttle(rightContentScroll))
    document.getElementsByClassName('left-div2')[0]?.addEventListener('scroll', throttle(leftScroll))
    if (utils.isIOS()) {
      bindTouchEvents()
    }
  })
})
onBeforeUnmount(() => {
  document.getElementsByClassName('right-div')[0]?.removeEventListener('scroll', rightScroll)
  document.getElementsByClassName('right-div2')[0]?.removeEventListener('scroll', rightContentScroll)
  document.getElementsByClassName('left-div2')[0]?.removeEventListener('scroll', leftScroll)
  // if (utils.isIOS()) {
  //   document.body.removeEventListener('touchstart', touchstartFunction)
  //   document.body.removeEventListener('touchmove', touchmoveFunction)
  // }

  emitter.off('financialsDataInit', financialsDataInit)
})
</script>
<style lang="scss" scoped>
:deep(.elv-table-tips-content) {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
}

.elv-report-container {
  width: 100%;
  height: v-bind('containerHeight');
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  padding-right: 20px;
  margin-bottom: 18px;
  padding-left: v-bind('props.paddingLeft');

  .left-div {
    flex: 0 0;
    flex-basis: auto;
    @media screen and (max-width: 1160px) {
      width: auto;
    }
    @media screen and (max-width: 820px) {
      width: 48vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }

    @media screen and (max-width: 768px) {
      width: 48vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    @media screen and (max-width: 390px) {
      width: 48vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }

  .left-div1 {
    display: flex;

    &::after {
      content: '';
      height: 100%;
      width: 10px;
      display: block;
    }
  }

  .left-div2 {
    max-height: v-bind('tableContentHeight');
    overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  :deep(.elv-table-right-content.el-scrollbar) {
    width: fit-content;
  }

  :deep(.right-div2) {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding-right: 14px;
    width: max-content;
    max-height: v-bind('tableContentHeight');
  }

  :deep(.right-div) {
    flex: 1;
    @media screen and (max-width: 820px) {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }

    @media screen and (max-width: 768px) {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }
    @media screen and (max-width: 390px) {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }
  }
}

table {
  //   width: 100%;
  text-align: center;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

table td {
  word-break: break-all;
  word-wrap: break-word;
}

.left-table2 {
  margin-bottom: 14px;
  border-right: 10px solid transparent;
  @media screen and (max-width: 820px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 390px) {
    margin-bottom: 40px;
  }

  tr {
    &:last-child {
      td {
        border-right: 0px;
      }
    }

    &::after {
      height: 100%;
      width: 0px;
      content: '';
      display: block;
    }
  }

  // td .elv-table-level-2-content span,
  // .elv-table-level-1-content > div {
  //   @media screen and (max-width: 820px) {
  //     max-width: 160px;
  //     overflow: hidden;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
  //     -o-text-overflow: ellipsis;
  //   }

  //   @media screen and (max-width: 768px) {
  //     max-width: 160px;
  //     overflow: hidden;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
  //     -o-text-overflow: ellipsis;
  //   }
  //   @media screen and (max-width: 390px) {
  //     max-width: 160px;
  //     overflow: hidden;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
  //     -o-text-overflow: ellipsis;
  //   }
  // }
}

.right-table1 {
  th {
    > div {
      width: 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: v-bind('titleJustifyContent');
    }
  }

  tr:first-child {
    th {
      &:nth-child(1) {
        border-right: 10px solid transparent;
        border-bottom-color: #1343bf !important;
      }
    }
  }
}

.right-table2 {
  margin-bottom: 14px;
  @media screen and (max-width: 820px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 390px) {
    margin-bottom: 40px;
  }

  tr {
    &:last-child {
      td {
        &:nth-child(1) {
          border-bottom-color: #1343bf !important;
        }
      }
    }
  }

  td {
    &:nth-child(1) {
      border-right: 10px solid transparent;
    }

    &.custom-made-border {
      border-bottom-width: 2px !important;
    }

    &.content_bold {
      font-weight: 800;
    }

    &.custom-made-content {
      height: 73px;
      border-bottom: 2px solid #000;

      &:first-of-type {
        border-bottom-color: #1343bf;
      }
    }
  }
}

th {
  height: 50px;
  vertical-align: bottom;
  padding-bottom: 10px;
}

.th-from-to-height {
  height: 70px;
}

tr {
  th {
    padding: 0px;

    &:nth-child(1) {
      color: #1343bf !important;

      .elv-table-right-title-date {
        color: #1343bf !important;
      }
    }
  }

  td {
    padding: 0px;
    height: 40px;

    &:nth-child(1) {
      border-right: 0px;
      // border-right: 10px solid transparent;
    }
  }

  &:last-child {
    td {
      border-bottom: 2px solid #1e2024;
    }
  }
}

.elv-table-left-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  color: #1e2024;
  font-size: 12px;
  text-align: left;
  padding-bottom: 8px;
}

.elv-table-right-title-date {
  font-family: 'Plus Jakarta Sans';
  color: #000;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  zoom: 0.83;
  -moz-transform: scale(0.83);
  -moz-transform-origin: top right;
}

.elv-table-level-3-content {
  display: flex;
  align-items: center;
  height: 40px;

  img {
    height: 20px;
    width: 20px;
    margin-left: 24px;
  }

  div {
    margin-left: 10px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    color: #0e0f11;
  }
}

.elv-table-level-3-content-symbol {
  margin-left: 10px;
  font-family: 'Plus Jakarta Sans' !important;
  font-weight: 400;
  font-size: 14px !important;
  text-transform: capitalize;
  color: #aaafb6 !important;
}

.elv-table-level-1-content {
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  height: 40px;
  line-height: 40px;
  padding-left: 24px;
  text-align: left;

  &.custom-made-content {
    box-sizing: border-box;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    height: 75px;
    padding: 24px 40px 16px 0px;
    border-bottom: 2px solid #1e2024;
  }
}

.elv-table-level-2-content {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  color: #0e0f11;
  display: flex;
  padding-left: 4px;
  padding-right: 20px;

  &.content_bold {
    font-weight: 800;
  }

  img {
    height: 16px;
    width: 16px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    display: block;
  }
}

.elv-table-title {
  white-space: pre;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 12px;
  // line-height: 21px;
  text-align: right;
}

.elv-table-double-solid-line {
  border-bottom: 2px solid #1e2024;
}

.elv-table-single-solid-line {
  border-bottom: 1px solid #1e2024;

  &.custom-made-border {
    border-bottom: 2px solid #1e2024;
  }
}

.elv-table-single-line {
  border-bottom: 1px solid #edf0f3;
}

.elv-table-double-solid-highlight-line {
  border-bottom: 2px solid #1343bf;
}

.elv-table-single-solid-highlight-line {
  border-bottom: 1px solid #1343bf;
  color: #1343bf !important;
}

.elv-table-single-highlight-line {
  border-bottom: 1px solid #cedaf8;
  color: #1343bf !important;
}

.elv-table-value-level-agg-text {
  width: 90px;
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  text-align: right;
}

.elv-table-value-level-2-3-text {
  width: 90px;
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  text-align: right;
}

.elv-table-value-text {
  min-width: 50px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  &.is-error {
    color: #eb3a30 !important;
  }
}

.cursor_none {
  cursor: default;
}
</style>
